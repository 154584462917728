<template>
  <div class="mb-1">
    <b-form-file
      ref="file-input"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      multiple
      @change="filesSelected"
    />
    <b-card class="choosen-input">
      <ul>
        <li
          v-for="file in files"
          :key="file.name"
        >
          {{ file.name }}
        </li>
      </ul>
    </b-card>
    <div class="text-right">
      <b-button
        variant="outline-primary"
        @click="
          clearFiles();
          files = null;
        "
      >Reset</b-button>
    </div>
    <hr class="dropdown-divider">
  </div>
</template>

<script>
import { BFormFile, BCard, BButton } from "bootstrap-vue";

export default {
  components: {
    BFormFile,
    BCard,
    BButton,
  },
  data() {
    return {
      files: null,
    };
  },
  methods: {
    filesSelected(e) {
      this.files = e.target.files;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },
  },
};
</script>

<style scoped>
.choosen-input {
  height: 50vh;
  overflow-y: auto;
}
</style>
